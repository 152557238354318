import { SET_INVENTORY_ACTION, SET_INVENTORY_ACTION_NULL } from "./PostTypes";

export function setTransferInvertoryAction(data) {
    return {
      type: SET_INVENTORY_ACTION,
      payload: data,
    };
  }

export function setTransferInvertoryActionNull() {
    return {
      type: SET_INVENTORY_ACTION_NULL
    };
}
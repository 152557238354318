import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
//import rootReducers from './reducers/Index';
import todoReducers from './reducers/Reducers';
import { reducer as reduxFormReducer } from 'redux-form';
import UserDetailsReducer from './reducers/UserDetailsReducers';
import ArticleDetailsReducer from './reducers/ArticleDetailsReducer';
import InventoryReducer from './reducers/InventoryReducer';

const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    posts: PostsReducer,
    auth: AuthReducer,
		todoReducers,
	form: reduxFormReducer,	
	UserData: UserDetailsReducer,
    ArticleData: ArticleDetailsReducer,
    inventory: InventoryReducer
});

//const store = createStore(rootReducers);

export const store = createStore(reducers,  composeEnhancers(middleware));

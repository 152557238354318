import React, { useState } from "react";
import { Card, Col, Modal, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { HiOutlineTrash } from "react-icons/hi";

function CreateInvoicePharma({ onHide, show, testData, setTestData }) {
  const [medicineName, setMedicineName] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [price, setPrice] = useState("");

  async function submit() {
    onHide();
  }

  function addTestoInvoiceList(e) {
    e.preventDefault();
     if (!medicineName) {
      toast.error("Enter medicine name");
      return;
    } else if (!description) {
      toast.error("Enter description");
      return;
    } else if (quantity == "0") {
      toast.error("Enter quantity");
      return;
    } else if (!price) {
      toast.error("Enter price");
      return;
    } else {
      if (testData.length == 0) {
        setTestData([{ medicineName, description, quantity, price }]);
      } else {
        setTestData((prev) => [
          ...prev,
          { medicineName, description, quantity, price },
        ]);
      }
      setMedicineName("");
      setDescription("");
      setPrice("");
      setQuantity("1");
    }
  }
  function removeMedicineFromList(ItemIndex) {
    const tempList = testData.filter((item, index) => ItemIndex !== index);
    setTestData([...tempList]);
  }

  return (
    <Modal
      className="modal fade"
      size="lg"
      centered
      onHide={onHide}
      show={show}
    >
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Create Invoice</h3>
              <Button variant="" className="close" onClick={() => onHide()}>
                <span style={{ fontSize: "30px" }}>&times;</span>
              </Button>
            </div>
          </Card.Header>

          <Card.Body>
            <form
              onSubmit={addTestoInvoiceList}
              className="d-flex align-items-start flex-column gap-2"
            >
              <input
                type="text"
                placeholder="Medicine Name"
                value={medicineName}
                onChange={(e) => setMedicineName(e.target.value)}
                className="form-control mr-2"
                name="medicineName"
              />
              <input
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="form-control mr-2"
                name="description"
              />
              <input
                type="text"
                placeholder="Medicine Name"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className="form-control mr-2"
                name="medicineName"
              />
              <input
                type="text"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="form-control mr-2"
                name="price"
              />
              <button className="btn btn-outline-primary" type="submit">
                Add
              </button>
            </form>
            {testData.length > 0 && (
              <ol className="">
                <div className="row mt-3">
                  <div className="col-4  pharma-card_distance invoice-table-header">
                    Medicine Name
                  </div>
                  <div className="col-4  pharma-card_distance invoice-table-header">
                    Quantity
                  </div>
                  <div className="col-2 pharma-card_distance invoice-table-header">
                    Price
                  </div>
                  <div className="col-2 pharma-card_distance invoice-table-header">
                    Action
                  </div>
                </div>
                {testData.map((item, index) => (
                  <div key={index} className="row mt-3">
                    <div className="col-4 pharma-card_distance">
                      <span className="font-weight-bold">{index + 1}.</span>{" "}
                      {item.medicineName}
                    </div>
                    <div className=" col-4 pharma-card_distance d-flex align-items-center">
                      <span className="">{item.quantity}</span>
                    </div>
                    <div className=" col-2 pharma-card_distance d-flex align-items-center">
                      <span className="">{item.price}</span>
                    </div>
                    <div
                      className=" col-2 pharma-card_distance d-flex align-items-center"
                      onClick={() => removeMedicineFromList(index)}
                    >
                      <HiOutlineTrash
                        size={"25px"}
                        color="red"
                        className="ml-2 pointer"
                      />
                    </div>
                  </div>
                ))}
              </ol>
            )}
          </Card.Body>
          <Card.Footer>
            <button className="btn btn-primary w-100" onClick={submit}>
              Add Invoice
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Modal>
  );
}

export default CreateInvoicePharma;

import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";

import Widget from "./pages/Widget";

import Login from "./pages/Login";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import Registration from "./pages/Registration";
import Orders from "./pages/Orders";
import OrderDetails from "./pages/OrderDetails";
import InventoryManagement from "./pages/InventoryManagement";
import LabOrders from "./pages/LabOrders";
import LabOrderDetails from "./pages/LabOrderDetails";
import CreateOrder from "./pages/CreateOrderLab";
import CreateOrderPharma from "./pages/CreateOrderPharma";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "page-login", component: Login },
    { url: "orders", component: Orders },
    { url: "lab-orders", component: LabOrders },

    { url: "orders/:orderId", component: OrderDetails },
    { url: "lab-orders/:orderId", component: LabOrderDetails },

    { url: "inventory", component: InventoryManagement },
    { url: "create-order", component: CreateOrder },
    { url: "create-order-pharma", component: CreateOrderPharma },

    // { url: "page-register", component: Registration },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;

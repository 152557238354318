import React, { useEffect, useState } from "react";
import { getLabOrders } from "../../services/AsyncFunctions";
import toast from "react-hot-toast";
import { Nav, Spinner, Tab } from "react-bootstrap";
import LabOrderCard from "../components/cards/LabOrderCard";

function LabOrders() {
  const [key, setKey] = useState("pending");
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPagePast, setCurrentPagePast] = useState(0);
  const [currentCompletedPage, setCurrentCompletedPage] = useState(0);
  const [currentCancelledPage, setCurrentCancelledPage] = useState(0);
  const [limit] = useState(10);
  const [hasMorePendingData, setHasMorePendingData] = useState(true);
  const [hasMoreOnGoingData, setHasMoreOnGoingData] = useState(true);
  const [hasMoreCompletedData, setHasMoreCompletedData] = useState(true);
  const [hasMoreCancelledData, setHasMoreCancelledData] = useState(true);
  const [pendingOrders, setPendingOrders] = useState(null);
  const [onGoingOrders, setOnGoingOrders] = useState(null);
  const [completedOrders, setCompletedOrders] = useState(null);
  const [cancelledOrders, setCancelledOrders] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function getPendingOrders() {
    setIsLoading(true);
    try {
      let response = await getLabOrders("pending", currentPage);
      let updatedList = pendingOrders
        ? [...pendingOrders, ...response.data.data]
        : [...response.data.data];
      setPendingOrders([...updatedList]);
      setHasMorePendingData(response.data.data.length < limit ? false : true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }
  async function getOnGoingOrders() {
    setIsLoading(true);
    try {
      let response = await getLabOrders("onGoing", currentPagePast);
      let updatedList = onGoingOrders
        ? [...onGoingOrders, ...response.data.data]
        : [...response.data.data];
      setOnGoingOrders([...updatedList]);
      setHasMoreOnGoingData(response.data.data.length < limit ? false : true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function getCompletedOrders() {
    setIsLoading(true);
    try {
      let response = await getLabOrders("completed", currentCompletedPage);
      let updatedList = completedOrders
        ? [...completedOrders, ...response.data.data]
        : [...response.data.data];
      setCompletedOrders([...updatedList]);
      setHasMoreCompletedData(response.data.data.length < limit ? false : true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function getCancelledOrders() {
    setIsLoading(true);
    try {
      let response = await getLabOrders("cancelled", currentCancelledPage);
      let updatedList = cancelledOrders
        ? [...cancelledOrders, ...response.data.data]
        : [...response.data.data];
      setCancelledOrders([...updatedList]);
      setHasMoreCancelledData(response.data.data.length < limit ? false : true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  function loadMoreAppointments(type) {
    if (type == "pending") {
      setCurrentPage((prev) => prev + 1);
    } else if (type == "completed") {
      setCurrentCompletedPage((prev) => prev + 1);
    } else if (type == "cancelled") {
      setCurrentCancelledPage((prev) => prev + 1);
    } else if (type == "onGoing") {
      setCurrentPagePast((prev) => prev + 1);
    }
  }

  useEffect(() => {
    getPendingOrders();
  }, [currentPage]);

  useEffect(() => {
    getOnGoingOrders();
  }, [currentPagePast]);

  useEffect(() => {
    getCompletedOrders();
  }, [currentCompletedPage]);

  useEffect(() => {
    getCancelledOrders();
  }, [currentCancelledPage]);

  return (
    <div>
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-none d-lg-block">
          <h2 className="text-black font-w600 mb-0">Orders</h2>
        </div>
      </div>
      <Tab.Container defaultActiveKey={key}>
        <div className="card-action tab-container card-tabs mt-3 mt-sm-0">
          <Nav as="ul" className="nav nav-tabs" role="tablist">
            <Nav.Item className="nav-item" onSelect={(k) => setKey(k)}>
              <Nav.Link
                className="nav-link"
                data-toggle="tab"
                to="#bounce"
                role="tab"
                eventKey="pending"
              >
                Pending Orders
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item" onSelect={(k) => setKey(k)}>
              <Nav.Link
                className="nav-link"
                data-toggle="tab"
                to="#bounce"
                role="tab"
                eventKey="onGoing"
              >
                Progress Orders
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item" onSelect={(k) => setKey(k)}>
              <Nav.Link
                className="nav-link"
                data-toggle="tab"
                to="#bounce"
                role="tab"
                eventKey="completed"
              >
                Completed Orders
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item" onSelect={(k) => setKey(k)}>
              <Nav.Link
                className="nav-link"
                data-toggle="tab"
                to="#bounce"
                role="tab"
                eventKey="cancelled"
              >
                Cancelled Orders
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <div className="card-body px-0">
          <Tab.Content>
            <Tab.Pane eventKey="pending">
              {pendingOrders &&
                pendingOrders.map((item) => (
                  <LabOrderCard
                    key={item._id}
                    patientName={item?.user?.name}
                    doctorName={item?.doctor?.name}
                    medicines={item?.test}
                    orderId={item?._id}
                    orderMode="Pending"
                  />
                ))}
              {pendingOrders && pendingOrders.length == 0 && (
                <div className="text-center">
                  <h3>No Data</h3>
                </div>
              )}
              {isLoading && (
                <div className="d-flex align-items-center justify-content-center mb-3">
                  <Spinner animation="border" variant="info" />
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center">
                {hasMorePendingData && !isLoading && (
                  <button
                    className="fs-14 p-2 px-3 btn-warning btn btn-xs m-auto"
                    onClick={() => loadMoreAppointments("pending")}
                  >
                    Load more
                  </button>
                )}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="onGoing">
              {onGoingOrders &&
                onGoingOrders.map((item) => (
                  <LabOrderCard
                    key={item._id}
                    patientName={item?.user?.name}
                    doctorName={item?.doctor?.name}
                    medicines={item?.test}
                    orderId={item?._id}
                    orderMode="On Going"
                  />
                ))}
              {onGoingOrders && onGoingOrders.length == 0 && (
                <div className="text-center">
                  <h3>No Data</h3>
                </div>
              )}
              {isLoading && (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner animation="border" variant="info" />
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center">
                {hasMoreOnGoingData && !isLoading && (
                  <button
                    className="fs-14 p-2 px-3 btn-warning btn btn-xs m-auto"
                    onClick={() => loadMoreAppointments("onGoing")}
                  >
                    Load more
                  </button>
                )}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="completed">
              {completedOrders &&
                completedOrders.map((item) => (
                  <LabOrderCard
                    key={item._id}
                    patientName={item?.user?.name}
                    doctorName={item?.doctor?.name}
                    medicines={item?.test}
                    orderId={item?._id}
                    orderMode="Completed"
                  />
                ))}
              {completedOrders && completedOrders.length == 0 && (
                <div className="text-center">
                  <h3>No Data</h3>
                </div>
              )}
              {isLoading && (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner animation="border" variant="info" />
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center">
                {hasMoreCompletedData && !isLoading && (
                  <button
                    className="fs-14 p-2 px-3 btn-warning btn btn-xs m-auto"
                    onClick={() => loadMoreAppointments("completed")}
                  >
                    Load more
                  </button>
                )}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="cancelled">
              {cancelledOrders &&
                cancelledOrders.map((item) => (
                  <LabOrderCard
                    key={item._id}
                    patientName={item?.user?.name}
                    doctorName={item?.doctor?.name}
                    medicines={item?.test}
                    orderId={item?._id}
                    orderMode="Cancelled"
                  />
                ))}
              {cancelledOrders && cancelledOrders.length == 0 && (
                <div className="text-center">
                  <h3>No Data</h3>
                </div>
              )}
              {isLoading && (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner animation="border" variant="info" />
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center">
                {hasMoreCancelledData && !isLoading && (
                  <button
                    className="fs-14 p-2 px-3 btn-warning btn btn-xs m-auto"
                    onClick={() => loadMoreAppointments("cancelled")}
                  >
                    Load more
                  </button>
                )}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
}

export default LabOrders;

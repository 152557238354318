import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { createInvoicePharma } from "../../services/AsyncFunctions";
import toast from "react-hot-toast";
import CreateInvoicePharma from "../components/modals/CreateInvoicePharma";

const CreateOrderPharma = () => {
  const [loading, setLoading] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [testData, setTestData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);

  async function submit() {
    let payload = {
      prescriptions:testData.map((item)=>{
        return {name:item.medicineName,description:item.description}
      }),
      invoice:testData.map((item)=>{
        return {medicineName:item.medicineName,price:item.price,quantity:item.quantity}
      }),
      totalPrice:testData.reduce((sum,item)=>{
        return sum + (+item.price)
      },0)
    }

    if (testData?.length > 0) {
      setLoading(true);
      try {
        const response = await createInvoicePharma(payload);
        console.log(response.data.statusCode)
        if(response.data.statusCode == 200){
          toast.success("Create Order");
        }
        setTestData([])
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    } 
  }

  useEffect(() => {
    if (testData.length > 0) {
      const totalAmount = testData.reduce(
        (total, item) => total + Number(item.price),
        0
      );
      setGrandTotal(totalAmount);
    }
  }, [testData]);

  return (
    <>
      <CreateInvoicePharma
        show={showTestModal}
        onHide={() => setShowTestModal(false)}
        testData={testData}
        setTestData={setTestData}
      />
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto w-100 d-flex align-items-center justify-content-between flex-wrap">
          <h2 className="text-black font-w600 mb-0">Create Order</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 flex-grow-1">
          <div className="d-flex w-100">
            <Card className="p-3  flex-grow-1">
              <div className="d-flex align-items-center justify-content-between">
                <h4>Medicine</h4>
                <button
                  className="btn btn-outline-primary p-2 px-3 fs-12"
                  onClick={() => setShowTestModal(true)}
                >
                  Create Order <i className="flaticon-381-plus"></i>{" "}
                </button>
              </div>
              <div>
                {testData?.map((item, index) => (
                  <div
                    key={item._id}
                    className="d-flex align-items-start flex-column mt-3"
                  >
                    <div className="pharma-card_distance mb-2">
                      <span className="font-weight-bold">{index + 1}.</span>{" "}
                      {item.medicineName}
                    </div>
                    <div className="pharma-card_description">
                      {item.description}
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        </div>
        {testData.length > 0 && (
          <div className="col-12 col-md-6 flex-grow-1">
            <div className="d-flex w-100">
              <Card className="p-3  flex-grow-1">
                <div className="d-flex align-items-center justify-content-around">
                  <h5 className="col-3">Test</h5>
                  <h5 className="col-3">Price</h5>
                </div>
                {testData?.map((item) => {
                  return (
                    <div className="d-flex align-items-start justify-content-around">
                      <div className="col-3">{item.medicineName}</div>
                      <div className="col-3">{item.price}</div>
                    </div>
                  );
                })}
                <div className="d-flex align-items-center justify-content-around mt-4">
                  <h5 className="col-3">Total</h5>
                  <h5 className="col-3">{grandTotal}</h5>
                </div>
                <button className="btn btn-primary w-100 mt-2" onClick={submit} disabled={loading}>
                  {loading ? "loading" : "Send Invoice"}
                </button>
              </Card>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateOrderPharma;

import moment from "moment";


// EventBus.js
class EventEmitter {
    constructor() {
      this.events = {};
    }
  
    on(eventName, callback) {
        let eventId = new Date().getTime();
        this.events[eventId] = {
            callback,
            eventName
        };
    //   this.events[eventId].callbackList.push(callback);
    }
  
    removeListener(eventId) {
      if (this.events[eventId]) {
        delete this.events[eventId];
      }
    }
    removeAllListeners(){
        this.events = {}
    }
  
    emit(eventName, data={}) {
        Object.keys(this.events).map(eventId => {
            if(this.events[eventId].eventName === eventName) {
                this.events[eventId].callback(data);
            }
        })
    }
  }
  
  const eventBus = new EventEmitter();
  export default eventBus;
  
import { SET_INVENTORY_ACTION } from "../actions/PostTypes";

const initialState = {
    inventoryData:null,
};

export default function InventoryReducer(state = initialState, actions) {
    if (actions.type === SET_INVENTORY_ACTION) {
      return {
        ...state,
        inventoryData: actions.payload
      }
    }else {
      return {
        ...state,
        inventoryData: null
      }
    }
}
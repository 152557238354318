import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import {
  CHANGE_PASSWORD_LAB,
  CHANGE_PASSWORD_PHARMACY,
  LOGIN,
  SIGNUP,
} from "./AuthApiEndPoints";
import axiosInstance from "./Instance";
import instance from "./Instance";

export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(data) {
  const postData = {
    email: data?.email,
    password: data?.password,
    type: data?.userType?.value,
  };

  return axios.post(LOGIN, postData);
}

export function signUpLab(data) {
  return axios.post(SIGNUP, data);
}

export function dashboard() {
  // return axiosInstance.get(DASHBOARD);
}
export function changePasswoardPharmacy(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };

  return axiosInstance.put(CHANGE_PASSWORD_PHARMACY, postData);
}
export function changePasswoardLab(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };

  return axiosInstance.put(CHANGE_PASSWORD_LAB, postData);
}
export function createGroup(formData) {
// 
}

export function editGroup(formData, id) {
  // return axiosInstance.put(CREATE_GROUP + `/${id}`, formData);
}
export function getAllGroup(currentPage, limit, search) {
  // const keywordParam = search ? `&keyword=${search}` : "";

  // return axiosInstance.get(
  //   GROUP_LIST + `?page=${currentPage}&perPage=${limit}${keywordParam}`
  // );
}

export function createHotelOwner(
  email,
  first_name,
  last_name,
  country,
  time_zone,
  user_type,
  number,
  permission
) {
  
}
export function deleteAdminAndOwner(id) {
  // return axiosInstance.delete(DELETE_ADMIN + `/${id}`);
}

export function getAllProperty(currentPage, limit, search) {
  // const keywordParam = search ? `&keyword=${search}` : "";

  // return axiosInstance.get(
  //   PROPERTY_LIST + `?page=${currentPage}&perPage=${limit}${keywordParam}`
  // );
}

export function getGroupId() {
  // return axiosInstance.get(GROUP_ID);
}
export function deleteGroup(id) {
  // return axiosInstance.delete(DELETE_GROUP + `/${id}`);
}

export function deleteProperty(id) {
  // return axiosInstance.delete(DELETE_PROPERTY + `/${id}`);
}
export function createProperty(formData) {
  // return axiosInstance.post(CREATE_PROPERTY, formData);
}
export function editProperty(formData, id) {
  // return axiosInstance.put(CREATE_PROPERTY + `/${id}`, formData);
}
export function logOut() {
  // const data = localStorage.getItem("anytime_phm_token");
  // const myHeaders = {
  //   Authorization: `Bearer ${data}`,
  // };

  // return instance.post(
  //   LOGOUT,
  //   { test: "" },
  //   {
  //     headers: myHeaders,
  //   }
  // );
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  //   tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000
  //   );
  localStorage.setItem("_anytime_pharm_token_", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  //   const tokenDetailsString = localStorage.getItem("userDetails");
  //   let tokenDetails = "";
  //   if (!tokenDetailsString) {
  //     dispatch(logout(history));
  //     return;
  //   }
  //   tokenDetails = JSON.parse(tokenDetailsString);
  //   let expireDate = new Date(tokenDetails.expireDate);
  //   let todaysDate = new Date();
  //   if (todaysDate > expireDate) {
  //     dispatch(logout(history));
  //     return;
  //   }
  //   dispatch(loginConfirmedAction(tokenDetails));
  //   const timer = expireDate.getTime() - todaysDate.getTime();
  //   runLogoutTimer(dispatch, timer, history);
}
